<template>
  <template v-if="!content">
    <component :is="tag" :class="{['f-'+fontSize]:fontSize}"
               :style="{fontSize:fontSize?.includes('px') ? fontSize : '', fontWeight, textAlign: align, height}">
      <slot></slot>
    </component>
  </template>
  <template v-else>
    <component :is="tag" :style="{fontSize, fontWeight, textAlign: align, height}">
      <span class="row" v-html="content"></span>
    </component>
  </template>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'h1'
    },
    fontSize: {
      type: String,
    },
    fontWeight: {
      type: String,
    },
    align: {
      type: String,
    },
    content: {
      type: String,
    },
    height: {
      type: String,
      default: 'auto'
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixins.scss";

h1, h2, h3, h4, h5 {
  margin: 0;
}

h1 {
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;

  @include smd {
    font-size: 24px;
  }

  &.f-36 {
    font-size: 36px;

    @include smd {
      font-size: 24px;
    }
  }
}

h2 {
  font-size: 30px;
  font-weight: 600;
  color: $dark-black;
  text-align: left;
}

h3, h5 {
  font-size: 14px;
  font-weight: 600;
}

h4 {
  font-size: 16px;
  font-weight: 500;
}

span {
  display: flex;
}
</style>
