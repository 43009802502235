<script setup>
import SideSlider from "~/components/templates/SideSlider.vue";
import {useCartStore} from "~/store/cartStore.js";
import Text from "~/components/atoms/Text.vue";
import Button from "~/components/atoms/Button.vue";
import ProductPrice from "~/components/molecules/ProductPrice.vue";
import SpoilerTabs from "~/components/molecules/SpoilerTabs.vue";
import InfoIcon from "~/components/icons/InfoIcon.vue";
import CartProducts from "~/components/organisms/Cart/CartProducts.vue";
import MarketCartIcon from "~/components/icons/MarketCartIcon.vue";

const store = useCartStore();

const clickBtn = () => {
  const checkout_url = '/checkout/';

  navigateTo(checkout_url)
}

</script>

<template>
  <SideSlider width="450px" @close="store.toggleCart()" :active="store.cartIsActive" position="right"
              :title="$t('cart.cart')">
    <div class="cart-container" @click.stop>
      <div v-if="store.content?.products?.length" style="overflow: auto">
        <CartProducts @click="store.toggleCart()"/>

        <div v-if="store.content.samples_count >= store.content.max_samples_count">
          <Text style="color:red;">
            <InfoIcon class="info-icon" style="width: 20px; transform: translateY(8px);" stroke="black"/>
            {{ $t('cart.max_sample') }}
          </Text>
        </div>

        <div style="border-top: 1px solid #e9eaf0">
          <SpoilerTabs
              class="tab"
              v-if="!store.content?.onlySample && store.content?.products?.length && store.content?.all_niches.every(item => item === 'Kakel och Klinker')"
              :tabs="[{title:'Viktig information när du beställer kakel/klinker', prefixComponent: InfoIcon}]">
            <template #tab-content-0>
              <Text class="extra-info">{{ $t('cart.extra_info.0') }} <br> <br> {{ $t('cart.extra_info.1') }}</Text>
            </template>
          </SpoilerTabs>
        </div>
      </div>
      <div v-else>
        <Text>{{ $t('cart.empty_cart') }}</Text>
      </div>

      <div class="total no-flex g-16">
        <div class="row full-width flex-end g-24" style="justify-content: flex-end;">
          <Text>{{ $t('cart.total') }}:</Text>
          <ProductPrice :key="store.content?.sub_total_sum" :price="store.content?.sub_total_sum"/>
        </div>

        <nuxt-link @click="store.toggleCart(); clickBtn();" v-if="store.content?.products?.length">
          <Button type="black" class="uppercase tall full-width coarse">
            <MarketCartIcon stroke="white"/> &nbsp; {{ $t('cart.to_checkout') }}
          </Button>
        </nuxt-link>
        <Button v-else @click="store.toggleCart" type="black" class="uppercase tall full-width">{{
            $t('base.back')
          }}
        </Button>
      </div>
    </div>
  </SideSlider>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.cart-container {
  padding: 0 24px;
  height: calc(100% - 80px);
  padding-top: 16px;
}

.info-icon {
  width: 20px;
  height: 20px;
}

.total {
  margin-top: auto;
  border-top: 1px solid $grey;
  padding: 20px 0;
}

.tab {
  &:deep(svg) {
    height: 20px;
    width: 20px;
  }
}

.extra-info {
  border: 2px dashed #707070;
  padding: 10px;
  margin-top: 10px;
  font-size: 12px;
  font-style: italic;

  //&:deep(.tab) {
  //  .heading {
  //    height: 40px !important;
  //  }
  //
  //  svg {
  //    max-width: 13px !important;
  //  }
  //
  //  h4 {
  //    font-size: 13px !important;
  //  }
  //}
}

</style>
