<script setup>

const props = defineProps({
  price: Number || String,
  digits: {
    type: Number,
    default: 2
  }
})
</script>

<template>
  {{
    price?.toLocaleString('sv-SE', {
      minimumFractionDigits: props.digits,
      maximumFractionDigits: props.digits
    }).replace(/\s/g, '').replace(',', '.')
  }}
</template>

<style scoped lang="scss">

</style>
