import {defineNuxtPlugin} from '#app'

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.vueApp.mixin({
        methods: {
            cutString(str, maxLength) {
                if (str.length > maxLength) {
                    return str.substring(0, maxLength) + '...';
                } else {
                    return str;
                }
            }
        }
    })
})
