export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('copy', {
        mounted(el, binding) {
            el.addEventListener('click', () => {
                navigator.clipboard.writeText(binding.value).then(() => {
                }).catch(err => {
                    console.error('Failed to copy text: ', err);
                });
            });
        }
    });
});
