<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <g clip-path="url(#clip0_2340_359)">
      <path d="M9.58203 1.66675V4.16675H7.08203" stroke-linecap="round" stroke-linejoin="round"/>
      <path
          d="M1.25 4.99992C1.25 4.58325 1.24769 4.20625 1.58077 3.46454C1.91385 2.72282 2.47897 2.10945 3.19097 1.71686C3.90298 1.32426 4.7233 1.17372 5.52831 1.2879C6.33332 1.40209 7.07942 1.77482 7.65416 2.34992L9.58333 4.16659"
          stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <g clip-path="url(#clip1_2340_359)">
      <path d="M0.417969 8.33325V5.83325H2.91797" stroke-linecap="round" stroke-linejoin="round"/>
      <path
          d="M8.75 5.00008C8.75 5.41675 8.75231 5.79375 8.41923 6.53546C8.08615 7.27718 7.52103 7.89055 6.80903 8.28314C6.09702 8.67574 5.2767 8.82628 4.47169 8.7121C3.66668 8.59791 2.92058 8.22518 2.34584 7.65008L0.41667 5.83341"
          stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_2340_359">
        <rect width="10" height="10" fill="white"/>
      </clipPath>
      <clipPath id="clip1_2340_359">
        <rect width="10" height="10" fill="white" transform="matrix(-1 0 0 -1 10 10)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'SpinnerIcon'
}
</script>
