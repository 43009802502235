<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g id="Icons/chevron-down">
      <path id="Vector" d="M7.5 15L12.5 10L7.5 5" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    stroke: {type: String, default: '#18181F'}
  }
}
</script>
