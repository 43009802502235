import {defineStore} from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => ({
        token: '',
        showAdmin: false,
        user: null
    }),
    getters: {
        isAuthenticated() {
            return this.token !== ''
        },
        isAdmin() {
            return this.user && this.user.role === 'Administrator'
        },
    },
    actions: {
        setUser(user) {
            this.user = user
        },
        setToken(token) {
            this.token = token
        },
        toggleShowAdmin() {
            this.showAdmin = !this.showAdmin
        }
    },
    persist: {
        key: 'userStore',
        storage: persistedState.cookiesWithOptions({
            sameSite: 'strict',
            maxAge: (60 * 60 * 24 * 7) * 4, // 1 week
        }),
    }
})
