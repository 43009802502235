import {defineStore} from 'pinia'

export const useStartupStore = defineStore('startup', {
    state: () => ({
        menu: {
            isMenuOpen: false,
            firstInit: false,
        },
        campaign: null,
        header_right: [],
        header_left: [],
        side_menu: [],
        top_menu: [],
        side_menu_collections: [],
        main_menu_footer: [],
        countries: [],
        reviews: [],
    }),
    getters: {
        isMenuInitialised(state) {
            return state.menu.firstInit
        },
        isMenuOpen(state) {
            return state.menu.isMenuOpen
        }
    },
    actions: {
        setReviews(reviews) {
            this.reviews = reviews
        },
        openMenu() {
            if (!this.menu.firstInit) {
                this.menu.firstInit = true
            }

            this.menu.isMenuOpen = true
        },
        closeMenu() {
            this.menu.isMenuOpen = false
        },
        toggleMenu() {
            if (!this.menu.firstInit) {
                this.menu.firstInit = true
            }
            this.menu.isMenuOpen = !this.menu.isMenuOpen
        }
    },
})
