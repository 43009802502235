// plugins/freshchat.js

export default defineNuxtPlugin(() => {
    if (process.client) {
        setTimeout(() => {
            function initFreshChat() {
                window.fcWidget.init({
                    token: '2ffed682-a0a3-44c3-988b-4537f5221951',
                    host: 'https://wchat.eu.freshchat.com'
                });
            }

            function initialize(i, t) {
                var e;
                if (i.getElementById(t)) {
                    initFreshChat();
                } else {
                    e = i.createElement('script');
                    e.id = t;
                    e.async = true;
                    e.src = 'https://wchat.eu.freshchat.com/js/widget.js';
                    e.onload = initFreshChat;
                    i.head.appendChild(e);
                }
            }

            function initiateCall() {
                initialize(document, 'Freshchat-js-sdk');
            }

            initiateCall();
        }, 3000);
    }
});
