<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M10 4.1665V15.8332" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.16406 10H15.8307" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'PlusIcon'
}
</script>
