<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g id="Icons/x">
      <path id="Vector" d="M12 4L4 12" stroke="#18181F" stroke-width="1.2" stroke-linecap="round"
            stroke-linejoin="round"/>
      <path id="Vector_2" d="M4 4L12 12" stroke="#18181F" stroke-width="1.2" stroke-linecap="round"
            stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CrossIcon'
}
</script>
