import { default as _91_46_46_46slug_93T9XBMq5kRdMeta } from "/var/www/projects/nuxt.hillceramic.se/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93wC3kDSJPH5Meta } from "/var/www/projects/nuxt.hillceramic.se/pages/blogg/[...slug].vue?macro=true";
import { default as index3husvfZMohMeta } from "/var/www/projects/nuxt.hillceramic.se/pages/blogg/index.vue?macro=true";
import { default as indexULhbZj5ZWjMeta } from "/var/www/projects/nuxt.hillceramic.se/pages/checkout/index.vue?macro=true";
import { default as payment3KvtXuiK1aMeta } from "/var/www/projects/nuxt.hillceramic.se/pages/checkout/payment.vue?macro=true";
import { default as index0d3kE39DrcMeta } from "/var/www/projects/nuxt.hillceramic.se/pages/checkout/thank-you/index.vue?macro=true";
import { default as indexYMEDg9dVhaMeta } from "/var/www/projects/nuxt.hillceramic.se/pages/collections/index.vue?macro=true";
import { default as _91slug_93i84qkvXs3HMeta } from "/var/www/projects/nuxt.hillceramic.se/pages/faq/[slug].vue?macro=true";
import { default as indexTp1q2jlQlAMeta } from "/var/www/projects/nuxt.hillceramic.se/pages/hillceramic-rabattkoder/index.vue?macro=true";
import { default as indexovwnG30XyoMeta } from "/var/www/projects/nuxt.hillceramic.se/pages/index.vue?macro=true";
import { default as indexMRUGyCtwLbMeta } from "/var/www/projects/nuxt.hillceramic.se/pages/login/index.vue?macro=true";
import { default as _91id_936zS5PA2hDhMeta } from "/var/www/projects/nuxt.hillceramic.se/pages/qr-pr/[id].vue?macro=true";
import { default as _91_46_46_46slug_931B0WTHYOmvMeta } from "/var/www/projects/nuxt.hillceramic.se/pages/shop/[...slug].vue?macro=true";
import { default as indexGTSnG3DOgYMeta } from "/var/www/projects/nuxt.hillceramic.se/pages/track/index.vue?macro=true";
export default [
  {
    name: "slug___sv",
    path: "/:slug(.*)*",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___da",
    path: "/da/:slug(.*)*",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blogg-slug___sv",
    path: "/blogg/:slug(.*)*",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/blogg/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blogg-slug___da",
    path: "/da/blogg/:slug(.*)*",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/blogg/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blogg___sv",
    path: "/blogg",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/blogg/index.vue").then(m => m.default || m)
  },
  {
    name: "blogg___da",
    path: "/da/blogg",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/blogg/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___sv",
    path: "/checkout",
    meta: indexULhbZj5ZWjMeta || {},
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___da",
    path: "/da/checkout",
    meta: indexULhbZj5ZWjMeta || {},
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-payment___sv",
    path: "/checkout/payment",
    meta: payment3KvtXuiK1aMeta || {},
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: "checkout-payment___da",
    path: "/da/checkout/payment",
    meta: payment3KvtXuiK1aMeta || {},
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: "checkout-thank-you___sv",
    path: "/checkout/thank-you",
    meta: index0d3kE39DrcMeta || {},
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/checkout/thank-you/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-thank-you___da",
    path: "/da/checkout/thank-you",
    meta: index0d3kE39DrcMeta || {},
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/checkout/thank-you/index.vue").then(m => m.default || m)
  },
  {
    name: "collections___sv",
    path: "/kollektioner",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "collections___da",
    path: "/da/samlinger",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "faq-slug___sv",
    path: "/faq/:slug()",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faq-slug___da",
    path: "/da/faq/:slug()",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: "hillceramic-rabattkoder___sv",
    path: "/hillceramic-rabattkoder",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/hillceramic-rabattkoder/index.vue").then(m => m.default || m)
  },
  {
    name: "hillceramic-rabattkoder___da",
    path: "/da/hillceramic-rabattkoder",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/hillceramic-rabattkoder/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sv",
    path: "/",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___da",
    path: "/da",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___sv",
    path: "/login",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___da",
    path: "/da/login",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "qr-pr-id___sv",
    path: "/qr-pr/:id()",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/qr-pr/[id].vue").then(m => m.default || m)
  },
  {
    name: "qr-pr-id___da",
    path: "/da/qr-pr/:id()",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/qr-pr/[id].vue").then(m => m.default || m)
  },
  {
    name: "shop-slug___sv",
    path: "/shop/:slug(.*)*",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/shop/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-slug___da",
    path: "/da/shop/:slug(.*)*",
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/shop/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "track___sv",
    path: "/track",
    meta: indexGTSnG3DOgYMeta || {},
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/track/index.vue").then(m => m.default || m)
  },
  {
    name: "track___da",
    path: "/da/track",
    meta: indexGTSnG3DOgYMeta || {},
    component: () => import("/var/www/projects/nuxt.hillceramic.se/pages/track/index.vue").then(m => m.default || m)
  }
]