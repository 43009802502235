export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.hook('page:finish', (page) => {
        const canonicalUrl = 'https://hillceramic.se' + useRoute().path;
        useHead({
            link: [
                {
                    rel: 'canonical',
                    href: canonicalUrl
                }
            ]
        });
    });
});
