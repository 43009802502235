<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.1667 7.5H9.66667C8.74619 7.5 8 8.24619 8 9.16667V16.6667C8 17.5871 8.74619 18.3333 9.66667 18.3333H17.1667C18.0871 18.3333 18.8333 17.5871 18.8333 16.6667V9.16667C18.8333 8.24619 18.0871 7.5 17.1667 7.5Z" stroke-width="1.2" stroke-linecap="round" :stroke="stroke" stroke-linejoin="round"/>
    <path d="M4.66675 12.5003H3.83341C3.39139 12.5003 2.96746 12.3247 2.6549 12.0122C2.34234 11.6996 2.16675 11.2757 2.16675 10.8337V3.33366C2.16675 2.89163 2.34234 2.46771 2.6549 2.15515C2.96746 1.84259 3.39139 1.66699 3.83341 1.66699H11.3334C11.7754 1.66699 12.1994 1.84259 12.5119 2.15515C12.8245 2.46771 13.0001 2.89163 13.0001 3.33366V4.16699" :stroke="stroke" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'CopyIcon',
  props: {
    stroke: {
      type: String,
      default: 'black'
    }
  }
}
</script>
