<script setup>
const props = defineProps({
  unit: String,
  sup: false
})
</script>

<template>
  <template v-if="unit === 'm2' && props.sup">m<sup>2</sup></template>
  <template v-else>{{ unit }}</template>
</template>
