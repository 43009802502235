<script setup>
const props = defineProps({
  type: {
    type: String,
    default: 'text'
  },
  placeholder: {
    type: String,
    default: ''
  },
  modelValue: [String, Number],
  width: {
    type: String,
    default: '100%'
  },
  classes: {
    type: Array,
    default: ['simple']
  },
  name: {
    type: String,
    default: ''
  },
  textAlign: {
    type: String,
  },
})

const emits = defineEmits(['change', 'update:modelValue'])
const value = computed(() => {
  return props.modelValue
})

const updateValue = (event) => {
  emits('update:modelValue', event.target.value);
}

const change = (event) => {
  emits('change', event.target.value);
}
</script>

<template>
  <input :name="name" :type="type"
         :style="{width: width,textAlign:textAlign}" :class="classes"
         @input="updateValue"
         @change="change"
         :placeholder="placeholder" v-model="value"/>
</template>

<style scoped>

input {
  &.simple {
    text-align: center;
    font-weight: 600;
    border: 0;
    outline: none;
  }
}

</style>
