<script setup lang="ts">

</script>

<template>
  <div class="box">
    <div class="circle__box">
      <div class="circle__wrapper circle__wrapper--right">
        <div class="circle__whole circle__right"></div>
      </div>
      <div class="circle__wrapper circle__wrapper--left">
        <div class="circle__whole circle__left"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
div {
  display: block;
}

.box {
  transform: scale(0.16);
  position: absolute;
  width: 48px;
  height: 48px;
  top: -25px;
  right: -4px;
}

.circle__box {
  width: 200px;
  height: 200px;
}

.circle__wrapper {
  width: 80px;
  height: 200px;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.circle__wrapper--right {
  right: 0;
  margin-right: 20px;
}

.circle__wrapper--left {
  left: 0;
  margin-left: 20px;
}

.circle__whole {
  width: 160px;
  height: 160px;
  border: 10px solid transparent;
  border-radius: 50%;
  position: absolute;
  top: 0;
  transform: rotate(-135deg);
}

.circle__right {
  border-top: 10px solid black;
  border-right: 10px solid black;
  right: 1px;
  animation: circleRight 2s linear forwards;
}

.circle__left {
  border-bottom: 10px solid black;
  border-left: 10px solid black;
  left: 1px;
  animation: circleLeft 2s linear forwards;
}

@keyframes circleRight {
  0% {
    transform: rotate(-135deg);
  }
  50%,
  100% {
    transform: rotate(45deg);
  }
}

@keyframes circleLeft {
  0%,
  50% {
    transform: rotate(-135deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}
</style>
