<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <path stroke-width="1.4px" d="M14.0026 25.6673C20.4459 25.6673 25.6693 20.444 25.6693 14.0007C25.6693 7.55733 20.4459 2.33398 14.0026 2.33398C7.55928 2.33398 2.33594 7.55733 2.33594 14.0007C2.33594 20.444 7.55928 25.6673 14.0026 25.6673Z" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14 18.6667V14" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14 9.33398H14.0117" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'InfoIcon'
}
</script>
