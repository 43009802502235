<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_8078_35739)">
      <path d="M6.0026 14.6667C6.37079 14.6667 6.66927 14.3682 6.66927 14C6.66927 13.6319 6.37079 13.3334 6.0026 13.3334C5.63441 13.3334 5.33594 13.6319 5.33594 14C5.33594 14.3682 5.63441 14.6667 6.0026 14.6667Z" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.3307 14.6667C13.6989 14.6667 13.9974 14.3682 13.9974 14C13.9974 13.6319 13.6989 13.3334 13.3307 13.3334C12.9625 13.3334 12.6641 13.6319 12.6641 14C12.6641 14.3682 12.9625 14.6667 13.3307 14.6667Z" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M0.664062 0.666626H3.33073L5.1174 9.59329C5.17836 9.90022 5.34534 10.1759 5.58909 10.3722C5.83285 10.5684 6.13786 10.6726 6.45073 10.6666H12.9307C13.2436 10.6726 13.5486 10.5684 13.7924 10.3722C14.0361 10.1759 14.2031 9.90022 14.2641 9.59329L15.3307 3.99996H3.9974" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_8078_35739">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'MarketCartIcon',
  props: {
    stroke: {
      type: String,
      default: 'black'
    }
  }
}
</script>
