import {createGtm} from "@gtm-support/vue-gtm";

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(createGtm({
        id: 'GTM-W7B98XC',
        defer: false,
        compatibility: false,
        enabled: true,
        debug: false,
        loadScript: true,
        vueRouter: nuxtApp.router,
        trackOnNextTick: false,
    }))
})
