<script setup>
import {ref, computed} from 'vue';
import Heading from "@/components/atoms/Heading.vue";
import ExpandIcon from "~/components/icons/ExpandIcon.vue";
import PlusIcon from "~/components/icons/PlusIcon.vue";
import MinusIcon from "~/components/icons/MinusIcon.vue";

const props = defineProps({
  tabs: Array,
  opened: Number,
  tag: {
    type: String,
    default: 'h4',
  },
  wrap: {
    type: Boolean,
    default: false,
  },
  arrows: {
    type: Boolean,
    default: true,
  },
  leftIcons: {
    type: Boolean,
    default: false,
  },
  classicSeparator: {
    type: Boolean,
    default: true,
  },
  bold: {
    type: Boolean,
    default: false,
  },
  borderedHeader: {
    type: Boolean,
    default: false,
  },
  extraPadding: {
    type: Boolean,
    default: false,
  },
});

const openTabs = ref([]);
if (props.opened !== undefined) {
  openTabs.value?.push(props.opened);
}

const toggleTab = (index) => {
  const tabIndex = openTabs.value.indexOf(index);
  if (tabIndex === -1) {
    openTabs.value?.push(index);
  } else {
    openTabs.value.splice(tabIndex, 1);
  }
};

const isActive = (index) => {
  return openTabs.value.includes(index);
};

onMounted(() => {
  props.tabs.forEach((tab, index) => {
    if (typeof tab !== 'string' && tab?.opened) {
      openTabs.value?.push(index);
    }
  });
});
</script>

<template>
  <div :class="{'no-classic-separator': !classicSeparator}">
    <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="tab no-flex"
        @click="toggleTab(index)"
    >
      <div
          class="heading row full-width"
          :class="{
          active: isActive(index),
          'left-icons': leftIcons,
          bold,
          'bordered-header': borderedHeader,
          'extra-padding': extraPadding,
        }"
      >
        <template v-if="leftIcons">
          <template v-if="arrows">
            <ExpandIcon class="expander"/>
          </template>
          <template v-else>
            <MinusIcon v-if="isActive(index)"/>
            <PlusIcon v-else/>
          </template>
        </template>

        <div class="row no-flex g-8 the-same-gap">
          <component
              :is="tab.prefixComponent"
              v-if="tab?.prefixComponent"
          />
          <Heading
              :tag="tag"
              class="title"
              :class="{wrap: wrap}"
              :content="typeof tab === 'string' ? tab : tab?.title"
          ></Heading>
        </div>

        <hr v-if="!classicSeparator && !borderedHeader"/>

        <template v-if="!leftIcons">
          <template v-if="arrows">
            <ExpandIcon class="expander"/>
          </template>
          <template v-else>
            <MinusIcon v-if="isActive(index)"/>
            <PlusIcon v-else/>
          </template>
        </template>
      </div>
      <div
          class="tab-content"
          @click.stop
          :class="{ active: isActive(index), 'extra-padding': extraPadding }"
      >
        <div style="padding-top: 8px;">
          <slot :name="'tab-content-' + (tab?.index ? tab.index : index)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixins.scss";

hr {
  height: 1px;
  width: 210px;
  border-top: 1px solid $grey;
  border-left: 0;

  @include smd {
    width: unset;
    flex: 1;
  }
}

.title {
  font-size: 14px;
  font-weight: 600;
}

.tab .heading {
  padding: 0 16px;
  cursor: pointer;
  align-items: center;
  border-top: 1px solid $grey;
  height: 52px;
  max-height: 52px;

  &:deep(h4) {
    font-size: 14px;
    text-wrap: wrap;
  }

  &.bold:deep(h4) {
    font-weight: 600;
  }

  &:hover {
    background-color: $grey;
    @include smd {
      background-color: unset;
    }
  }
}

.tab:first-child .heading {
  border-top: none;
}

.tab-content {
  padding: 0 16px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.tab-content:not(.active) {
  max-height: 0;
}

.tab-content.active {
  max-height: 5000px;
  transition: all 0.6s ease-in-out;
}

.expander {
  width: 22px;
  transform: rotate(90deg);
  margin-left: auto;
}

.heading.active .expander {
  transform: rotate(-90deg);
}

svg {
  stroke: $black;
  margin-left: auto;
  min-width: 20px;
  min-height: 20px;
}

.left-icons svg {
  margin-left: unset;
  margin-right: 10px;
}

.no-classic-separator .tab .heading {
  border-top: none;
  gap: 20px;
}

.bordered-header {
  border-top: 1px solid $border-grey !important;
  border-bottom: 0;
}

.extra-padding {
  padding-left: 40px !important;
}

h4:deep(span) {
  text-wrap: nowrap;
}

h4.wrap:deep(span) {
  text-wrap: wrap;
}
</style>
